import React from "react";
import Slider from "react-slick";
import "./css/Whyavon.scss";

import {
  FaChessKing,
  FaChessBishop,
  FaChessKnight,
  FaChessPawn,
  FaChessQueen,
  FaChessRook,
  FaChess,
  FaChessBoard,
} from "react-icons/fa";

function Whyavon({ lid }) {
  const data = [
    {
      image: "assets/icons/1.svg",
      head: "Enquiry",
      desc: "The starting point of sales and purchases. Enquiries lead to the relevant vendor/supplier information along with specs sharing and packing details.",
    },
    {
      image: "assets/icons/2.svg",
      head: "Quote",
      desc: "Quotations include pricing, delivery timelines, payment terms, custom clearance charges, VAT, etc. After accepting a quote, the prerequisites are ordered.",
    },
    {
      image: "assets/icons/3.svg",
      head: "Order Confirmation",
      desc: `When your purchase order and our sales order are in agreement with one another, those orders combine to form a legally binding contract between us to work according to your specifications`,
    },
    {
      image: "assets/icons/4.svg",
      head: "Payment",
      desc: `A letter of credit from a bank guaranteeing that a buyer’s payment to a seller will be received on time and for the correct amount. If the buyer is unable to make a payment on the purchase, the bank will be required to cover the full or remaining amount of the purchase.`,
    },
    {
      image: "assets/icons/5.svg",
      head: "Production",
      desc: "A complete production and delivery timeline will be provided to the customer upon receiving the Letter of Credit.",
    },

    {
      image: "assets/icons/6.svg",
      head: "Quality Control",
      desc: "We ensure that the manufactured products adhere to a defined set of quality criteria and meet customer needs.",
    },

    {
      image: "assets/icons/7.svg",
      head: "Shipping & Logistics",
      desc: "We ensure timely shipments and also help our customers in Warehousing, Customs Brokerage, Tariff Calculation and transportation management.",
    },
    {
      image: "assets/icons/aftersales.svg",
      head: "After Sales Service",
      desc: `We provide any assistance that is required by our valuable clients after they've purchased a product. It covers technical assistance, product inspection and claim management.`,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="Whyavon" id={lid}>
      <>
        <h6 className="heading">Our Process</h6>
        {window.screen.width > 1000 ? (
          <>
            <div className="avanmapdata">
              {data.map((item, index) => {
                return (
                  <div key={index} className="simfstcol">
                    <span className="icon">
                      <img src={item.image} alt={item.head} />
                    </span>
                    <div className="divi">
                      <p className="headinginner">{item.head}</p>
                      <p className="parainner">{item.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="sliderWrapper">
              <Slider {...settings}>
                {data.map((item, index) => {
                  return (
                    <div key={index} className="sliderSimfstCol">
                      <span className="icon">
                        <img src={item.image} alt="" />
                      </span>
                      <div className="divi">
                        <p className="headinginner">{item.head}</p>
                        <p className="parainner">{item.desc}</p>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </>
        )}
      </>
    </div>
  );
}

export default Whyavon;
