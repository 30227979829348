import React, { useRef, useState } from "react";
import Aboutnewsec from "../components/Aboutnewsec";
import Banner from "../components/Banner";
import Ceomessage from "../components/Ceomessage";
import Customslider from "../components/Customslider";
import Faq from "../components/Faq";
import Founder from "../components/Founder";
import Homebusinesssegment from "../components/Homebusinesssegment";
import Leftimgsec from "../components/Leftimgsec";
import Letsconnect from "../components/Letsconnect";
import Ourteam from "../components/Ourteam";
import Rightimgsec from "../components/Rightimgsec";
import Whyavon from "../components/Whyavon";
import About from "./About";
import Contact from "./Contact";
import "./Home.scss";
import { Helmet } from "react-helmet";

function Home({ aboutRef }) {
  const faq = [
    [
      "<h4>How do I include a Mixture or Gas in My Stock?</h4>",
      `<p>In the case of a chemical mixture (e.g., a gas mixture), it is essential to account for all associated dangers. Two-component combinations are accounted for according to the more dangerous component.</p>
            <p>Select Add to Inventory from the Avon Commercial homepage, search for and add the container information for the most dangerous chemical component (e.g., 5% Carbon monoxide in Nitrogen is added as Carbon monoxide).
            </p>
            <p>Enter the numerical Concentration, Concentration Units, and Solvent for the mixture between "Tags" and "Received Date" (for example, concentration = 5, units = % wt, solvent = Nitrogen). If the mixture's solvent is not provided, simply fill in the solvent's name.
            </p>
            <p>Save the container's details. Multiple components or combinations require their own entry in the Avon Commercial library and safety data sheet (SDS) since the mixture may present a new combination of dangers. 
            </p>`,
    ],

    [
      "<h4>How can I get a quote?</h4>",
      `<p>We recommend filling out the online form on our website to receive a quote for your chemicals.</p>
            <p>To immediately request a quote by email, please use the email address located beside the Let's Connect Form. Requests for estimates will be handled and answered within 24 hours. In circumstances of extreme urgency (such as when purchasing Rapid or Express service), we recommend calling Customer Service.
            </p>
            `,
    ],

    [
      "<h4>Should I pay in advance?</h4>",
      `<p>Yes, but not in all instances.</p>
            <p>Our preferred payment method is a prior period invoice. The due date for payment of the prior period invoice enclosed with the order confirmation is the date of shipment.</p>
            <p>Our contractual clients can also pay against final invoice.</p>`,
    ],
    [
      "<h4>Will I consistently receive my order on the date specified in the confirmation?</h4>",
      `<p>No. Following receipt of the prior period invoice, we deliver the order. On the day the order is completed, you will be notified and, if applicable, asked to verify that the transfer has been made.</p>
            <p>Our contractual customers have the option to pay upon final invoice. Please contact our customer service for information regarding our contract terms.</p>
            <p>Please refer to the order's reference number for identification purposes when transferring!
            </p>`,
    ],
    [
      `<h4>How long will it take to receive my order?</h4>`,
      ` <p>Our standard delivery time is 08-to-10 business days from the date of order, but you can also select Rapid or Express delivery on the order form.</p>
            <p>The document accompanying the confirmation includes the delivery date.</p>
            <p>We deliver the order on the date specified in the order confirmation, no later than the end of the working day.</p>`,
    ],
  ];
  const dataright = {
    h1: "Textile Raw",
    h2: "Material & Chemical",
    p: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when anunknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting.`,
    image: "assets/home/textileraw.png",
  };

  const dataleft = {
    h1: "Our Services",
    h2: "",
    p: `
     <p>Avon Commercial Corporation has been successfully connecting manufacturers and customers globally, providing maximum services right up until the point of delivery.
     </p>
     <p> Avon Commercial Corporation is committed to maintain a reliable, responsible and flexible supply chain process of sourcing raw materials for its customers.
     </p>
     <p>We continuously strive to introduce economical brands without compromising on the quality and secure supply.
     </p>
     <p>Our dynamic product development team regularly participates in important international events and trade exhibitions in order to conduct research on the most recent trends within the industrial sector. </p>
        `,
    image: "assets/1service.webp",
    image2: "assets/services5.webp",
  };

  const about = {
    h1: "About ",
    h2: " Avon Commercial Corporation",
    p: `Avon Commercial has provided diversified Sourcing Solutions to the manufacturing industry worldwide for the past six decades.
        <br/><span>
        We supply commodities needed to develop, sustain and improve businesses.
        </span>   <br/>
        The company's sustainable growth results from maintaining a reliable supply chain of commodities and timely and quality-conscious services provided to our customers around the globe.`,
    image: "assets/about/vision.png",
  };

  const aboutus = {
    para: `<h1>Founder's Message</h1>
        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <p> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
        <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
    img: "./assets/founder.png",
  };

  const ref = useRef(null);

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>Global Sourcing Company in Pakistan - Avon Commercial</title>
        <link rel="canonical" href="https://www.avoncommercial.net/" />
        <meta
          name="description"
          content="Avon Commercial is your global product sourcing company and raw material supplier. We provide top-quality materials worldwide. Enhance your supply chain today!"
          data-react-helmet="true"
        />

        {/* open graph */}
        <meta property="og:url" content="https://www.avoncommercial.net/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Global Sourcing Company in Pakistan - Avon Commercial"
        />
        <meta
          property="og:description"
          content="Avon Commercial is your global product sourcing company and raw material supplier. We provide top-quality materials worldwide. Enhance your supply chain today!"
        />
        <meta property="og:image" content="assets/home/4.webp" />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content="https://www.avoncommercial.net/"
        />
        <meta
          name="twitter:title"
          content="Global Sourcing Company in Pakistan - Avon Commercial"
        />
        <meta
          name="twitter:description"
          content="Avon Commercial is your global product sourcing company and raw material supplier. We provide top-quality materials worldwide. Enhance your supply chain today!"
        />
        <meta name="twitter:image" content="assets/home/4.webp" />
        <link rel="canonical" href="https://www.avoncommercial.net/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Global Sourcing Company in Pakistan - Avon Commercial"
        />
        <meta
          property="og:description"
          content="Avon Commercial is your global product sourcing company and raw material supplier. We provide top-quality materials worldwide. Enhance your supply chain today!"
        />
        <meta property="og:url" content="https://www.avoncommercial.net/" />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>
      <section className="home">
        <Banner image="assets/home/4.webp" />
        {/* <Rightimgsec data={about} lid='about' aboutRef={aboutRef} /> */}
        <Aboutnewsec />
        {/* <Founder lid="about" data={aboutus} /> */}
        <Homebusinesssegment />
        <Leftimgsec data={dataleft} lid="service" />
        {/* <Ceomessage lid="ceo" data={ceo} /> */}
        {/* <Ourteam /> */}

        {/* <About /> */}
        {/* <Letsconnect /> */}
        <Whyavon lid="process" />
        {/* <Faq faq={faq} service='Home' para='Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing .' /> */}

        <div className="seoCont">
          <h1>Global Sourcing Company in Pakistan</h1>
          <p>
            Avon Commercial takes pride in its role as a leading global sourcing
            company based in Pakistan. We have built a strong reputation as a
            reliable raw material supplier and product-sourcing company by
            adhering to an unyielding dedication to excellence. Our company
            serves a wide range of industries on a global scale.{" "}
          </p>
          <p>
            Our commitment to achieving the highest possible standards in
            sourcing has earned us the reputation of a reliable global sourcing
            company in Pakistan. We act as a bridge between businesses and their
            requirements, paving the way for streamlined processes and
            encouraging growth through collaboration.{" "}
          </p>
          <h2>Product Sourcing Company </h2>
          <p>
            As a distinguished product global sourcing company, Avon Commercial
            efficiently connects businesses with the required materials. With a
            meticulous approach to sourcing, we streamline the process, ensuring
            that businesses receive high-quality materials that align with their
            production needs.{" "}
          </p>
          <p>
            Leveraging the extensive network and industry expertise,
            contributing to the growth and success of our partners! Serving both
            as raw material supplier and as a corporation that sources finished
            goods. With a commitment to reliability, quality, and innovation,
            Avon Commercial continues to shape the global sourcing landscape,
            setting benchmarks for efficiency and customer satisfaction.{" "}
          </p>
        </div>
      </section>
    </>
  );
}

export default Home;
