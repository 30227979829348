import React, { useState } from "react";
import { useEffect } from "react";
import "./css/Banner.scss";
function Banner({ image }) {
  let [url, setUrl] = useState("");
  useEffect(() => {
    let url = window.location.href.split("/").at(-1);
    setUrl(url);
  }, []);

  let goToContact = () => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector("#footerForm").getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        100,
    });
  };

  return (
    <div
      className="banner"
      style={{
        backgroundImage:
          window.screen.width > 740
            ? `url("assets/home/4.webp")`
            : `url("assets/home/5.webp")`,
      }}
      aria-label="Global Raw Material Supplier & Product Sourcing Company"
    >
      {/* `url("assets/home/4a.png")` */}
      <div className="bannerdata">
        <h5 className="h1">
          Your Global <br /> Business Partner
        </h5>

        <button onClick={goToContact}>Contact us</button>
        {/* {window.screen.width > 800 ? (
          <button
            onClick={() => {
              window.scrollTo(0, 9999);
            }}
          >
            Contact us
          </button>
        ) : (
          <a href="tel:+92345 2183128"> Contact us</a>
        )} */}
      </div>
    </div>
  );
}

export default Banner;
