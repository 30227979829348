import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./css/Popupform.scss";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
function Popupform({ title, btnstyle }) {
  const [show, setShow] = useState(false);
  // show hide form
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // ---------------------------------------------------------------------

  const [isSubmit, setIsSubmit] = useState(false);
  const formRef = useRef(null);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  let initialVailue = {
    name: null,
    email: null,
    number: null,
    Subject: null,
    message: null,
  };

  const url = "https://avon.admin.simboz.com/public/api/send-contact-query";
  let navigate = useNavigate();
  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const RunSubmit = () => {
    // formRef.current.submit();
    onSubmit();
  };

  const onSubmit = (e) => {
    const { name, email, number, Subject, message } = formData;

    console.log(formData);

    if (name != null && email != null && number != null) {
      const _formaData = new FormData();
      _formaData.append("name", name);
      _formaData.append("email", email);
      _formaData.append("number", number);
      _formaData.append("company", `Quote for ${title}`);
      _formaData.append("query", message);

      axios
        .post(url, _formaData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            Swal.fire(
              "Query Sent Successfully",
              "Thank you for contacting Us, One of our representative will get back to you shortly",
              "success"
            );
            setFormData({
              name: null,
              email: null,
              number: null,
              Subject: null,
              message: null,
            });
            handleClose();
            navigate("/form-submit");
          }
        })
        .then(reset);
    } else {
      Swal.fire({
        icon: "error",
        title: "Fill all fields...",
      });
    }
  };

  return (
    <div className="popupwrapper">
      <Button
        // className='quote'
        className={btnstyle}
        onClick={handleShow}
      >
        Get Quote
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="modal myModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="title">
            {title ? title : `<p>Get Quote</p>`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mobpara">
            Contact us to get the exclusive details and product conformity for
            exclusive budget rates.
          </p>
          <form
            ref={formRef}
            onSubmit={handleSubmit((e) => onSubmit(e))}
            onKeyUp={getData}
            className="modalbody"
            method="post"
          >
            <input
              placeholder="Full Name*"
              required
              type="text"
              // name="name"

              {...register("name", {
                required: "name is Required",
              })}
            />

            <input
              placeholder="Email*"
              required
              type="email"
              // name="email"

              {...register("email", {
                required: "email is Required",
                // maxLength: 20,
              })}
            />

            <input
              placeholder="Number*"
              required
              type="text"
              // name="number"

              {...register("number", {
                required: "number is Required",
                maxLength: 20,
                pattern: {
                  value: /^\+?\d+/i,
                  message: "error message",
                },
              })}
            />
            <textarea
              placeholder="Message*"
              required
              type="text"
              // name="email"

              {...register("message", {
                //   required: "message is Required",
                // maxLength: 20,
              })}
            />

            <div className="captcha"></div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <div className="btnwrapper">
            <button onClick={RunSubmit} className="button">
              {" "}
              Submit{" "}
            </button>
            <button onClick={handleClose} className="close">
              {" "}
              Close{" "}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popupform;
