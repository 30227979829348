import React from "react";
import "./PagenotFound.scss";
import { Link } from "react-router-dom";

function PagenotFound() {
  return (
    <div className="pagenotfound">
      <p>Something went wrong.</p>
      <h2>Page Not Found</h2>
      <Link to={'/'}>Go back to Home</Link>
    </div>
  );
}

export default PagenotFound;
