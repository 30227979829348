import "./contact.scss";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import Mapbanner from "../components/Mapbanner";

function Contact() {
  let sttop = () => {
    window.scrollTo(0, 0);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function createMarkup() {
    return { __html: "First &middot; Second" };
  }
  useEffect(() => {
    sttop();
  }, []);
  // ----------------form data

  const [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  let initialVailue = {
    name: "",
    email: "",
    number: "",
    Subject: "",
    message: "",
  };

  const url = "https://avon.admin.simboz.com/public/api/send-contact-query";

  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (data) => {
    const { name, email, number, Subject, message } = formData;
    console.log(name);
    console.log(email);
    console.log(number);

    if ((name != "", email != "", number != "", Subject != "")) {
      console.log(formData);

      const _formaData = new FormData();
      _formaData.append("name", name);
      _formaData.append("email", email);
      _formaData.append("number", number);
      _formaData.append("company", Subject);
      _formaData.append("query", message);

      axios
        .post(url, _formaData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            Swal.fire(
              "Query Sent Successfully",
              "Thank you for contacting Us, One of our representative will get back to you shortly",
              "success"
            );
            setFormData({
              name: null,
              email: null,
              number: null,
              Subject: null,
              message: null,
            });
          }
        })
        .then(reset);
    } else {
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>Contact us</title>
        <link rel="canonical" href="https://www.avoncommercial.net/contact" />
        <meta
          name="description"
          content="Contact-Us"
          data-react-helmet="true"
        />
        {/* open graph */}
        <meta
          property="og:url"
          content="https://www.avoncommercial.net/contact"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact us" />
        <meta property="og:description" content="Contact us" />
        <meta
          property="og:image"
          content="https://www.avoncommercial.net/assets/logo/avon.svg"
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content="https://www.avoncommercial.net/contact"
        />
        <meta name="twitter:title" content="Contact us" />
        <meta name="twitter:description" content="Contact us" />
        <meta
          name="twitter:image"
          content="https://www.avoncommercial.net/assets/logo/avon.svg"
        />
        <link rel="canonical" href="https://www.avoncommercial.net/contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact us" />
        <meta property="og:description" content="Contact us" />
        <meta
          property="og:url"
          content="https://www.avoncommercial.net/contact"
        />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>
      <Mapbanner />
      <div className="contact" id="contact">
        <h1 className="heading">Get in Touch with us!</h1>

        <div className="towseccol">
          <div className="infosec">
            <div className="inner">
              <span>
                <FaMapMarkerAlt className="icon" aria-label="Location" />
              </span>
              <a href="https://goo.gl/maps/DS6Etw42wAzM5QHK6" target="_blank">
                Address: Building # 5-C, Second Floor, 26th Street,
                Khayaban-e-Sehar, Phase 6, D.H.A, Karachi, Pakistan.
              </a>
            </div>
            <div className="inner">
              <span>
                <FaPhoneAlt className="icon" aria-label="Contact" />
              </span>
              <p>
                Phone: +(92-21) 35241213-5
                <br />
                Cell: <a href="tel:03451507878 "> +(92) 3451507878 </a>
                 
              </p>
            </div>
            <div className="inner">
              <span>
                <FaEnvelope className="icon" aria-label="Email" />
              </span>
              <div>
                <a href="mailto:avon@avoncommercial.net">
                  Email: avon@avoncommercial.net
                </a>
                <br />
                <a href="mailto:avoncom@yahoo.com">Email: avoncom@yahoo.com</a>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} onKeyUp={getData}>
            <div className="contactinnerright">
              <div className="twofc">
                <input
                  placeholder="Full Name*"
                  required
                  type="text"
                  // name="name"

                  {...register("name", {
                    required: "name is Required",
                  })}
                />
                <input
                  placeholder="Email*"
                  required
                  type="email"
                  // name="email"

                  {...register("email", {
                    required: "email is Required",
                    // maxLength: 20,
                  })}
                />
                <input
                  placeholder="Number*"
                  required
                  type="text"
                  // name="number"

                  {...register("number", {
                    required: "number is Required",
                    maxLength: 20,
                    pattern: {
                      value: /^\+?\d+/i,
                      message: "error message",
                    },
                  })}
                />
              </div>
              {/* -- */}
              <input
                placeholder="Subject*"
                required
                type="text"
                {...register("Subject", {
                  required: "Subject is Required",
                })}
              />
              {/*  */}
              <textarea
                placeholder="Message*"
                required
                type="text"
                // name="email"

                {...register("message", {
                  //   required: "message is Required",
                  // maxLength: 20,
                })}
              />

              <button type="submit" className="contactbtn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Contact;
