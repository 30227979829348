import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "./Context/ProductsContext";
import "./css/Product.scss";
import Popupform from "./Popupform";
import { Helmet } from "react-helmet";
function Product() {
  let { singleproduct, catimage } = useContext(ProductContext);
  let [sp, setSp] = useState();

  let [blog, setBlog] = useState();

  let [image, setImage] = useState(catimage);

  let slug = window.location.href.split("/").at(-1);
  let url = `https://avon.admin.simboz.com/public/api/get-product/${slug}`;

  let blogsurl = "https://avon.admin.simboz.com/public/api/get-blogs";

  useEffect(() => {
    let sttop = () => {
      window.scrollTo(0, 0);
    };
    sttop();
    if (singleproduct == undefined) {
      axios.get(url).then(function (response) {
        setSp(response.data.product);
        let img = localStorage.getItem("image");
        setImage(img);
      });
      axios.get(blogsurl).then(function (response) {
        setBlog(response.data.blogs);
        // console.log(blog);
      });
    } else {
      setSp(singleproduct);
    }
  }, []);

  function createMarkup(item) {
    return { __html: item };
  }

  // console.log(slug, "slug");
  console.log(sp, "setSp");
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>{sp != undefined && (sp.meta_title || sp.title)}</title>

        {/* <title>{sp != undefined && sp.title}</title> */}

        {/*  */}
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/products/${slug}`}
        />
        <meta
          name="description"
          content={sp != undefined && (sp.meta_description || sp.summary)}
          // data-react-helmet="true"
        />
        {/* open graph */}
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/products/${slug}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={sp != undefined && (sp.meta_title || sp.title)}
        />
        <meta
          property="og:description"
          content={sp != undefined && (sp.meta_description || sp.summary)}
        />
        <meta property="og:image" content={sp != undefined && sp.image} />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content={`https://www.avoncommercial.net/products/${slug}`}
        />
        <meta
          name="twitter:title"
          content={sp != undefined && (sp.meta_title || sp.title)}
        />
        <meta
          name="twitter:description"
          content={sp != undefined && (sp.meta_description || sp.summary)}
        />
        <meta name="twitter:image" content={sp != undefined && sp.image} />
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/products/${slug}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={sp != undefined && (sp.meta_title || sp.title)}
        />
        <meta
          property="og:description"
          content={sp != undefined && (sp.meta_description || sp.summary)}
        />
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/products/${slug}`}
        />
        <meta property="og:site_name" content="Avon Commercial" />
        {/* open graph */}
      </Helmet>
      <div className="productmain">
        <div className="productmainpage">
          {sp != undefined ? (
            <div className="inner">
              <div className="productupper">
                <div className="imagesec">
                  {/* <img src={`/${image}`} alt="" /> */}
                  <img src={sp.image} alt={sp.title} />

                  {/* <img src={'/assets/service/pro.jpg'} alt="" /> */}
                </div>
                <div className="summry">
                  {sp.meta_footer ? <h3>{sp.title}</h3> : <h1>{sp.title}</h1>}
                  <p>{sp.summary}</p>
                  <div className="btnsec">
                    {/* <button>Get Quote</button> */}
                    <Popupform title={sp.title} />
                    <button>
                      <a href="mailto:avoncom@yahoo.com">Send Email</a>
                    </button>
                  </div>
                </div>
              </div>
              <div className="descdiv">
                <p className="h6">Description:</p>
                <div
                  dangerouslySetInnerHTML={createMarkup(sp.description)}
                  className="desc"
                ></div>
              </div>
              {sp.meta_footer ? (
                <div
                  className="seoCont"
                  dangerouslySetInnerHTML={createMarkup(sp.meta_footer)}
                ></div>
              ) : null}
            </div>
          ) : (
            <img src={"/assets/service/loadingicon.gif"} />
          )}
        </div>
      </div>
    </>
  );
}

export default Product;
