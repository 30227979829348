import React from "react";
import "./FabricLandPage.scss";
import ListingBanner from "../components/ListingBanner";
import ListingMapProducts from "../components/ListingMapProducts";
import Leftimgsec from "../components/Leftimgsec";
import ListingLeftSec from "../components/ListingLeftSec";
import Whyavon from "../components/Whyavon";
import LetsWork from "../components/LetsWork";
import { Helmet } from "react-helmet";
function FabricLandPage() {
  const dataleft = {
    h1: "Embrace Creativity with Our Premium Fabrics Collection. ",
    h2: "",
    p: `
     <p>
     Avon Commercial, your one-stop destination for premium fabrics. Explore a diverse collection crafted 
     for fashion, home decor, and more. Elevate your designs with quality textiles, backed by timely 
     delivery and exceptional customer service. Unleash your creativity and bring your vision to life with 
     our exquisite fabrics.
     </p>

        `,
    image: "assets/listing/fabric.webp",
  };

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>High-Quality Wholesale Fabrics Supplier in Pakistan</title>
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/fabric-landing-page`}
        />
        <meta
          name="description"
          content={`Elevate your designs with high-quality textiles. We're a leading wholesale fabrics supplier in Pakistan, offering premium materials for your creations`}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="FabricLandPage">
        <ListingBanner
          title={"Fabric"}
          image={"assets/listing/fiberbanner.webp"}
        />
        <ListingLeftSec data={dataleft} lid="service" />
        <ListingMapProducts active={"1"} />
        <Whyavon lid="process" />
        <LetsWork />
      </div>
    </>
  );
}

export default FabricLandPage;
