import React from "react";
import "./css/Leftimgsec.scss";
function Leftimgsec({ data, lid }) {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <div className="Leftimgsec" id={lid ? lid : null}>
      <div className="leftinner">
        <div className="imgwrapper">
          <img src={data.image} alt="Services of Avon Commercial Corporation" />
        </div>
        <div className="textwrapper">
          <div className="inner">
            <h6 className="h1">
              <span>{data.h1} </span>
              <br />
              {data.h2}
            </h6>
            <div
              dangerouslySetInnerHTML={createMarkup(data.p)}
              className="dataWrapper"
            ></div>
            {/* <button className='seemore'>See More</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Leftimgsec;
