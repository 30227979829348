import React from "react";
import { Link } from "react-router-dom";
import "./css/Ceomessage.scss";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

function Ceomessage({ lid, data }) {
  function createMarkup(cont) {
    return { __html: cont };
  }

  return (
    <div
      className="ceomessage"
      id={lid ? lid : "ceo"}
      style={{
        backgroundImage: `url("./assets/about/2.webp")`,
      }}
    >
      <div className="inner"></div>

      <div className="content">
        <div className="contentinner">
          <div className="message">
            <div className="rel">
              <div
                className="inner2"
                // dangerouslySetInnerHTML={createMarkup(data.para)}
              >
                <FaQuoteLeft className="icon1" />
                <p>
                  It gives me great pleasure to welcome you and I hope you learn
                  more about the qualities that makes us a premier sourcing
                  house.
                </p>

                <p>
                  We have earned the trust and respect of our clients for one
                  simple reason—we have great people. They are experts in their
                  fields and bring a strong service ethos to everything they do.
                  Our objective is to ensure our clients mission objectives are
                  achieved with the highest level of capability. Our methodology
                  ensures that services are delivered on time and we always
                  strive to take it to the next level of performance.
                </p>

                <p>
                  Our corporate culture is defined by a simple set of corporate
                  values that define how we operate every single day – Trust,
                  Care, Passion, and Pride.
                </p>
                <FaQuoteRight className="icon2" />
              </div>
              <div className="abs">
                <p
                  className="founder"
                  // dangerouslySetInnerHTML={createMarkup(data.title)}
                >
                  Ghazanfar Shahid Awan
                </p>
                <p
                  className="founder"
                  // dangerouslySetInnerHTML={createMarkup(data.title2)}
                >
                  Chief Executive Officer
                </p>
              </div>
            </div>
          </div>

          <div className="manimg">
            <img src={data.img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ceomessage;
