import React from "react";
import Centercont from "../components/Centercont";
import Cta from "../components/Cta";
import Leftimgsec from "../components/Leftimgsec";
import Rightimgsec from "../components/Rightimgsec";
import Subbanner from "../components/Subbanner";
import "./About.scss";
import Founder from "../components/Founder";
import Ceomessage from "../components/Ceomessage";
import Ourteam from "../components/Ourteam";
import { Helmet } from "react-helmet";

function About({ aboutRef }) {
  // let centerdata = {
  //   h1: "Sourcing Solutions",
  //   p: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially.",
  // };
  // const dataright = {
  //   h1: "VISION",
  //   h2: "",
  //   p: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,`,
  //   image: "assets/about/vision.png",
  // };
  // const dataleft = {
  //   h1: "MISSION",
  //   h2: "",
  //   p: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to remaining essentially unchanged.`,
  //   image: "assets/about/mission.png",
  // };
  const aboutus = {
    para: `
        <p>Embarking six decades ago, we commenced a journey to weave interconnected solutions for industries globally. Beyond mere supplies, we nurture businesses with essential elements that foster growth and innovation.
        </p>
        <p>Our legacy thrives as we orchestrate a symphony of sustainability through seamless commodity pathways, enveloping the world in the embrace of timely excellence.
        </p>
        <p>With the best products and solutions, we strive to provide a valuable experience for all. We ask for your continued interest and support for Avon Commercial Corporation.
        </p>`,
    img: "./assets/founder.webp",
    title: `Shahid Latif Awan`,
    title2: `Founder & Chairman`,
  };

  const ceo = {
    // hedi: `<h1>CEO's Message</h1>`,
    para: `

        <p>It gives me great pleasure to welcome you and I hope you learn more about the qualities that makes us a premier sourcing house.</p>

        <p>We have earned the trust and respect of our clients for one simple reason—we have great people. They are experts in their fields and bring a strong service ethos to everything they do. Our objective is to ensure our clients mission objectives are achieved with the highest level of capability. Our methodology ensures that services are delivered on time and we always strive to take it to the next level of performance.</p>

        <p>Our corporate culture is defined by a simple set of corporate values that define how we operate every single day – Trust, Care, Passion, and Pride.</p>

      `,
    img: "./assets/man.webp",
    title: `Ghazanfar Shahid Awan`,
    title2: `Chief Executive Officer`,
  };
  setTimeout(() => {
    console.log(aboutRef);
  }, 2000);

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>About Us</title>
        <meta name="About Us" content="About Us" />

        {/* open graph */}
        <meta
          property="og:url"
          content="https://www.avoncommercial.net/about"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Us" />
        <meta
          property="og:description"
          content="Avon Commercial has been providing diversified sourcing solutions to the manufacturing industry worldwide since past six decades."
        />
        <meta
          property="og:image"
          content="https://www.avoncommercial.net/assets/about/about.webp"
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content="https://www.avoncommercial.net/about"
        />
        <meta name="twitter:title" content="About Us" />
        <meta
          name="twitter:description"
          content="Avon Commercial has been providing diversified sourcing solutions to the manufacturing industry worldwide since past six decades."
        />
        <meta
          name="twitter:image"
          content="https://www.avoncommercial.net/assets/about/about.webp"
        />
        <link rel="canonical" href="https://www.avoncommercial.net/about" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="About Us" />
        <meta
          property="og:description"
          content="Avon Commercial has been providing diversified sourcing solutions to the manufacturing industry worldwide since past six decades."
        />
        <meta
          property="og:url"
          content="https://www.avoncommercial.net/about"
        />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>

      <div className="about" id="about" ref={aboutRef}>
        <Subbanner image="assets/about/about.webp" heading="About Us" />
        <Founder lid="founder" data={aboutus} />
        <Ceomessage lid="ceo" data={ceo} />
        <Ourteam />

        {/* 
        <Centercont centerdata={centerdata} />
        <Rightimgsec data={dataright} />
        <Cta />
        <Leftimgsec data={dataleft} /> 
        */}
      </div>
    </>
  );
}

export default About;
