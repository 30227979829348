import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subbanner from "../components/Subbanner";
import "./Services.scss";
import { useContext } from "react";
import { ProductContext } from "../components/Context/ProductsContext";
import Popupform from "../components/Popupform";
import { Helmet } from "react-helmet";

function Services() {
  let sttop = () => {
    window.scrollTo(0, 0);
  };

  let url = "https://avon.admin.simboz.com/public/api/get-products";

  let {
    allproducts,
    setAllproducts,
    activetab,
    setActivetab,
    setSingleproduct,
    catimage,
    setCatimage,
    subCategory,
    setSubCategory,
  } = useContext(ProductContext);

  let [loading, setLoading] = useState(false);
  let [sorted, setSorted] = useState([]);

  useEffect(() => {
    sttop();

    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);

        let scat = response.data.cats[activetab];
        // console.log(scat.products, 'sd')

        let b = scat.products.sort((a, b) => {
          return a.sort_by - b.sort_by;
        });

        // console.log(b, 'sorted data')

        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // console.log(allproducts);

  let imagearr = [
    "assets/service/fabric.png",
    "assets/service/textile.png",
    "assets/service/petrochemical.png",
    "assets/service/solvents.png",
    "assets/service/basicchemical.png",
    "assets/service/filmsandsheet.png",
  ];

  localStorage.setItem("image", imagearr[activetab]);

  function createMarkup(item) {
    return { __html: item };
  }
  // meta code .

  let [metadata, setMetadata] = useState({
    title: "",
    desc: "",
  });
  let urlL = window.location.href.split("/").at(-1);
  // console.log(activetab, "activetab");
  // console.log(allproducts);
  useEffect(() => {
    if (urlL == "fibers-&-yarns") {
      setMetadata({
        title: "Fiber Supplier in Pakistan | Raw Cotton Supplier in Pakistan",
        desc: "Your reliable source for fibers in Pakistan. We specialize as a trusted raw cotton supplier, ensuring quality materials for diverse industrial applications.",
        seoData: `<h1>Premier Fiber Supplier in Pakistan - Avon Commercial</h1>
        <p>
        Avon Commercial takes pride in being the premier fiber supplier in Pakistan, specializing in providing top-quality raw materials for diverse industries. As a trusted name in the market, our commitment to excellence extends to being a reliable raw cotton supplier. Explore a comprehensive range of fibers, including raw cotton, and elevate your manufacturing processes with Avon Commercial as your dedicated partner. As a leading Cotton Supplier in Pakistan, we pride ourselves on offering a diverse selection of fibers to meet the unique needs of businesses across various sectors, ensuring a one-stop solution for all your raw material requirements.
        </p>

        <h2>Best Yarn Suppliers in Pakistan: Avon Commercial</h2>
        <p>Avon Commercial is a top yarn supplier in Pakistan, supplying a wide variety of high-quality yarns to fulfill the needs of various businesses across the country. With a strong commitment to excellence, Avon Commercial is your reliable source for high-quality yarns that improve your manufacturing processes. As the market's top supplier, we take pride in offering a diverse range of yarns, including polyester, cotton, and blended varieties, ensuring that businesses have access to the best fibers for their production needs. With Avon Commercial as your dedicated yarn supplier, you are guaranteed unmatched quality, reliability, and service, making us the top choice for businesses searching excellence in yarn procurement.</p>

        <h2>Unrivaled Quality in Raw Cotton Supply</h2>
        <p>
        At Avon Commercial, we understand the critical role raw materials play in the production cycle. As a distinguished raw cotton supplier in Pakistan, we offer unrivaled quality in our products. Our commitment to sourcing the finest cotton ensures that businesses receive fibers meeting the highest standards of softness, durability, and versatility. Choose Avon Commercial for raw cotton that forms the foundation of exceptional products in various industries. Our extensive experience as a Fiber Supplier allows us to provide not only top-notch raw cotton but also expert insights and support to optimize your manufacturing processes for efficiency and quality.
        </p>
        <h2>Avon Commercial - Your Trusted Cotton Supplier in Pakistan</h2>
        <p>
        Avon Commercial stands at the forefront as your trusted fiber supplier, delivering excellence in every strand of raw cotton. With a focus on meeting the dynamic demands of the Pakistani market, we cater to businesses of all scales – from small-scale entrepreneurs to large-scale industries. Choose Avon Commercial as your cotton supplier for a reliable partnership that ensures the seamless availability of high-quality fibers, contributing to the success of your manufacturing endeavors. As one of the leading Cotton Suppliers in Pakistan, we are dedicated to fostering long-term relationships with our clients by consistently providing superior quality fibers and unmatched customer service.
        </p>`,
      });
      setActivetab(0);
    } else if (urlL === "textiles") {
      setMetadata({
        title: "Top Fabrics Supplier in Pakistan | Textile Fabrics Supplier",
        desc: "Your top fabrics supplier in Pakistan offering a wide range of textile fabrics. Trusted fabric supplier with a commitment to quality and innovation.",
        seoData: ` <h1>Avon Commercial - Your Top Fabrics Supplier in Pakistan</h1>
        <p>
        Avon Commercial proudly holds the title of the top fabrics supplier in Pakistan, committed to providing exceptional textile fabrics to meet the diverse needs of the industry. As a reliable and innovative partner, we understand the importance of superior materials in textile manufacturing. Explore our extensive range of fabrics and experience the difference when you choose Avon Commercial as your dedicated textile fabrics supplier. Our comprehensive selection of fabrics caters to a wide array of applications, ensuring that we stand as your go-to choice for all your fabric needs. Trust Avon Commercial to elevate your textile creations with quality fabrics that set the standard in the industry.
        </p>
        <h2>Unmatched Expertise as Textile Fabrics Supplier</h2>
        <p>
        With a wealth of experience, Avon Commercial stands out as a distinguished textile fabrics supplier in Pakistan. Our commitment to excellence is reflected in the quality and versatility of our fabric offerings. As textile agents in Pakistan, we go beyond traditional supply, ensuring that our fabrics meet the highest industry standards, contributing to the success and innovation of textile manufacturers across the country. Our role as Textile Agents in Pakistan enables us to provide not only top-tier fabrics but also valuable insights and solutions to enhance your textile manufacturing processes.
        </p>
        <h2>Your Trusted Fabric Supplier in Pakistan - Avon Commercial</h2>
        <p>
        Avon Commercial is not just a fabric supplier; we are your trusted partner in textile endeavors. Whether you are a small-scale business or a large-scale industry, our commitment to delivering top-tier fabrics remains unwavering. Choose Avon Commercial as your fabric supplier in Pakistan, and benefit from our dedication to quality, innovation, and customer satisfaction in every woven thread. As a Fabric Supplier in Pakistan, we take pride in fostering enduring relationships with our clients, offering not just fabrics but a collaborative partnership that propels the success of your textile ventures.
        </p>
       `,
      });
      setActivetab(1);
    } else if (urlL === "petrochemicals") {
      setMetadata({
        title: "Petrochemicals Supplier in Pakistan | Resin Supplier",
        desc: "Your reliable Petrochemicals Supplier in Pakistan. Choose excellence with our trusted Resin and Polymer solutions for diverse industrial applications.",
        seoData: `   <h1>Polymer Supplier in Pakistan - Unraveling Innovation at Avon Commercial</h1>
        <p>
        As a leading petrochemical supplier in Pakistan, Avon Commercial takes pride in its role as a premier polymer supplier. Our commitment to innovation is showcased through an extensive range of high-quality polymers tailored to meet the dynamic needs of various industries. Whether you're in plastics, textiles, or manufacturing, Avon Commercial stands as a reliable partner, providing polymers that embody excellence, versatility, and adherence to the highest industry standards. Choose us as your polymer supplier to infuse your products with the cutting-edge materials needed for success in a competitive market. Avon Commercial, your trusted source for innovative polymers, is committed to propelling your business forward with materials that not only meet but exceed industry expectations.
        </p>
      
        <h2>Resin Supplier Delivering Excellence - Avon Commercial's Commitment</h2>
        <p>
        Avon Commercial, with its distinguished position as a resin supplier in Pakistan, remains unwavering in its commitment to excellence. Our extensive range of resins caters to the multifaceted needs of industries such as construction, adhesives, and coatings. As a trusted partner, we ensure that our resins are not only of the highest quality but also align with the evolving demands of the market. When you choose Avon Commercial as your resin supplier, you're choosing a reliable source that prioritizes durability, performance, and the success of your manufacturing processes. Count on Avon Commercial to provide not just resins but a partnership that enhances the resilience and longevity of your products.
        </p>
        <h2>Petrochemical Supplier of Choice - Avon Commercial's Versatility and Quality</h2>
        <p>
        Avon Commercial stands at the forefront as the petrochemical supplier of choice in Pakistan. Our commitment to delivering top-quality raw materials extends beyond polymers and resins. With a diverse range of petrochemical products, we cater to the multifaceted needs of the Pakistani market. From small-scale entrepreneurs to large-scale industries, our products are tailored to meet varying production scales, ensuring that businesses have a steadfast source for the versatile and durable materials necessary for success in an ever-evolving industrial landscape. Choose Avon Commercial as your trusted partner on the journey toward sustainable growth. As a Petrochemical Supplier, we not only provide materials but also contribute to the strategic growth and resilience of your business with our versatile and quality products.
        </p>
        <h2>Resin Solutions Tailored to Your Needs</h2>
        <p>Avon Commercial stands as the premier resin supplier in Pakistan, dedicated to delivering exceptional products and service. With a comprehensive range of resins tailored to diverse industrial applications, we ensure unmatched quality and performance. As your trusted resin supplier in Pakistan, we prioritize reliability and innovation to meet the evolving needs of the market. Partnering with Avon Commercial means accessing a wealth of expertise and resources dedicated to enhancing the durability and functionality of your products. Choose Avon Commercial as your resin supplier in Pakistan, and experience excellence in every aspect of your manufacturing processes.</p>
        `,
      });
      setActivetab(2);
    } else if (urlL === "solvents") {
      setMetadata({
        title: "Solvents Supplier in Pakistan | Industrial Solvents Supplier",
        desc: "Your trusted Solvents Supplier in Pakistan, specializing in high-quality Industrial Solvents. Explore our reliable solutions for your diverse industrial needs.",
        seoData: `    <h1>Avon Commercial - Your Reliable Solvents Supplier in Pakistan</h1>
        <p>
        Avon Commercial takes pride in being a reliable solvents supplier in Pakistan, specializing in industrial solvents for various applications. As a trusted name in the market, we are committed to delivering top-notch solvents to cater to diverse industrial needs. Choose Avon Commercial as your dedicated solvent supplier, ensuring efficiency, safety, and adherence to rigorous industry standards. Our extensive selection of solvents caters to a wide range of industries, making us the go-to choice for businesses seeking a comprehensive and reliable source for their solvent requirements.
        </p>
        <h2>Unparalleled Quality in Industrial Solvents Supply </h2>
        <p>
        Discover unparalleled quality with Avon Commercial's industrial solvents. As a leading solvent supplier in Pakistan, we understand the pivotal role these components play in numerous industries, including manufacturing, electronics, and automotive. Our solvents are engineered to ensure optimal performance, efficiency, and safety, meeting the stringent requirements of modern industrial processes. When you choose Avon Commercial, you're not just selecting solvents; you're investing in a partnership that prioritizes quality and precision in every industrial application. 
        </p>
        <h2>Avon Commercial - The Foremost Choice for Solvent Solutions</h2>
        <p>Avon Commercial stands as the foremost solvent supplier in Pakistan, offering a comprehensive range of industrial and organic solvents. Our commitment to innovation and excellence is resolute as we continue to refine our offerings. For businesses seeking superior solvent solutions tailored to their needs, Avon Commercial is the partner you can rely on for optimal performance and adherence to sustainable practices. As your foremost Solvent Supplier in Pakistan, we are dedicated to providing not only high-quality solvents but also innovative solutions that contribute to the success and efficiency of your industrial processes. Trust Avon Commercial to be your strategic partner in solvent supply, ensuring your business stays at the forefront of industry standards.</p>`,
      });
      setActivetab(3);
    } else if (urlL === "basic-chemicals") {
      setMetadata({
        title: "Industrial Chemicals Supplier in Pakistan | Organic Chemical",
        desc: "Your go-to Chemicals Supplier in Pakistan. Explore our range of quality Industrial Chemicals and Organic Chemicals for reliable solutions and superior performance.",
        seoData: ` <div>
        <h1>Avon Commercial - Your Trusted Industrial Chemicals Supplier in Pakistan</h1>
        <p>
        Avon Commercial stands as a trusted industrial chemicals supplier in Pakistan, offering a comprehensive range of high-quality products to meet the diverse needs of various industries. As a dedicated partner, we are committed to providing top-tier industrial and organic chemicals, contributing to the success and growth of businesses across the spectrum. With our extensive portfolio of chemicals, we strive to be more than just a supplier; we aim to be your strategic ally in achieving excellence in your industrial processes.
        </p>
        <h2>Unrivaled Excellence in Chemical Supply</h2>
        <p>
        Experience unrivaled excellence with Avon Commercial's industrial chemicals. As a prominent Industrial chemicals supplier in Pakistan, we understand the importance of these compounds in various manufacturing processes. Our commitment to quality and efficiency positions us as a cornerstone in the chemical supply chain, supporting the growth and success of industries through top-tier chemical solutions. When you choose Avon Commercial, you are not just obtaining chemicals; you are securing a reliable source that prioritizes excellence and precision in every chemical supply.
        </p>
        <h2>Avon Commercial - Paving the Way for Sustainable Chemical Solutions</h2>
        <p>
        In a dynamic industrial landscape, the role of a trusted chemical supplier is indispensable. Avon Commercial's dedication to innovation and environmental responsibility makes us a vital partner in Pakistan's chemical supply chain. Choose us for a reliable source of industrial and organic chemicals, contributing to a greener future while fulfilling the chemical needs of industries across the nation. As your dedicated Chemical Supplier in Pakistan, we are committed to not only meeting but exceeding your expectations, offering sustainable chemical solutions that align with evolving industry standards and environmental consciousness. Trust Avon Commercial to lead the way towards a more sustainable and efficient chemical future for your business.
        </p>
      </div>`,
      });
      setActivetab(4);
    } else if (urlL === "films-&-sheets") {
      setMetadata({
        title: "Plastic Film & Sheet Supplier in Pakistan | Packaging Sheets",
        desc: "Avon Commercial is global sourcing solutions. We are the leading plastic sheets supplier, offering plastic film and packaging sheets in Pakistan.",
        seoData: `<h1>Plastic Sheets Supplier</h1>
        <p>
          Avon Commercial takes pride in being a leading plastic sheets supplier,
          offering a diverse and high-quality range of plastic sheet solutions to
          meet a broad spectrum of industrial and commercial needs. Plastic sheets
          are integral components used across industries for their versatility,
          durability, and cost-effectiveness, making Avon Commercial a dependable
          partner for sourcing these essential materials.
        </p>
        <p>
          Avon Commercial's dedication to providing top-tier packaging sheets in
          Pakistan and a focus on sustainability positions us as a trusted partner
          for businesses seeking reliable packaging and industrial materials. With
          our vast range of offerings and commitment to quality, we empower
          industries across Pakistan to excel in their operations and meet the
          challenges of a dynamic market.
        </p>
        <h2>Packaging Sheets in Pakistan</h2>
        <p>
          In the realm of packaging, Avon Commercial stands out as a reliable
          provider of packaging sheets in Pakistan. Packaging sheets, crafted from
          various types of plastic, are essential for protecting goods during
          transit, maintaining product integrity, and enhancing shelf appeal. As the
          demand for efficient and sustainable packaging solutions grows, Avon
          Commercial offers a range of packaging sheets catering to the unique
          requirements of businesses nationwide.
        </p>
        <p>
          In the realm of packaging, Avon Commercial stands out as a reliable
          provider of packaging sheets in Pakistan. Packaging sheets, crafted from
          various types of plastic, are essential for protecting goods during
          transit, maintaining product integrity, and enhancing shelf appeal. As the
          demand for efficient and sustainable packaging solutions grows, Avon
          Commercial offers a range of packaging sheets catering to the unique
          requirements of businesses nationwide.
        </p>`,
      });
      setActivetab(5);
    } else if (urlL === "ferrous-scrap") {
      setMetadata({
        title: "",
        desc: "",
        seoData: ``,
      });
      setActivetab(6);
      setSubCategory(0);
    } else if (urlL === "non-ferrous-scrap") {
      setMetadata({
        title: "",
        desc: "",
        seoData: ``,
      });
      setActivetab(6);
      setSubCategory(1);
    } else if (urlL === "re-rollable-scrap") {
      setMetadata({
        title: "",
        desc: "",
        seoData: ``,
      });
      setActivetab(6);
      setSubCategory(2);
    } else if (urlL === "isri-specifications") {
      setMetadata({
        title: "",
        desc: "",
        seoData: ``,
      });
      setActivetab(6);
      setSubCategory(3);
    } else {
      setMetadata({
        title: "",
        desc: "",
        seoData: ``,
      });
      setActivetab(6);
    }
  }, [urlL]);

  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>{metadata.title}</title>
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta
          name="description"
          content={metadata.desc}
          data-react-helmet="true"
        />

        {/* open graph */}
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${metadata.title}`} />
        <meta property="og:description" content={`${metadata.desc}`} />
        <meta
          property="og:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="byeindonesia.com" />
        <meta
          property="twitter:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta name="twitter:title" content={`${metadata.title}`} />
        <meta name="twitter:description" content={`${metadata.desc}`} />
        <meta
          name="twitter:image"
          content="https://www.avoncommercial.net/assets/service/service.webp"
        />
        <link
          rel="canonical"
          href={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${metadata.title}`} />
        <meta property="og:description" content={`${metadata.desc}`} />
        <meta
          property="og:url"
          content={`https://www.avoncommercial.net/business-segment/${urlL}`}
        />
        <meta property="og:site_name" content="Avon Commercial" />

        {/* open graph */}
      </Helmet>
      {/* {console.log(`https://www.avoncommercial.net/business-segment/${urlL}`)} */}
      <div className="services">
        <Subbanner
          image={"/assets/service/service.webp"}
          title={metadata.title}
          newTitle={allproducts[activetab]}
          catIndex={subCategory}
        />

        <div className="productwrap">
          {loading ? (
            <div className="divider">
              <div className="catsec">
                {allproducts?.map((item, index) => {
                  return (
                    <div key={index}>
                      <p
                        className={
                          index == activetab ? "activecategory" : "categoryname"
                        }
                        onClick={() => {
                          return setActivetab(index), setSubCategory("hide");
                        }}
                      >
                        {item.category}
                      </p>
                      {/* <div className="subCatWrapper">
                        {item.subcategories?.map((item, index) => {
                          return (
                            <p key={index} className='singleSubCategory'>
                              {item.category}
                            </p>
                          )
                        })}
                        <div>
                        </div>
                      </div> */}
                    </div>
                  );
                })}
              </div>
              <div className="productsec">
                {subCategory == "hide" ? (
                  <>
                    {allproducts[activetab]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>

                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                    {allproducts[activetab]?.subcategories.map(
                      (item, index) => {
                        return (
                          <div key={index} className="productbox">
                            <div className="upperwrap">
                              <p className="heading">{item.category}</p>
                              <p className="desc">{item.description}</p>
                            </div>
                            <button
                              className="button"
                              onClick={() => {
                                setSubCategory(index);
                              }}
                            >
                              View Products
                            </button>
                          </div>
                        );
                      }
                    )}
                    {/* print categories */}
                  </>
                ) : (
                  <>
                    {allproducts[activetab]?.subcategories[
                      subCategory
                    ]?.products.map((item, index) => {
                      return (
                        <div key={index} className="productbox">
                          <div className="upperwrap">
                            <Link
                              to={`/products/${item.slug}`}
                              onClick={() => {
                                return (
                                  setSingleproduct(item),
                                  setCatimage(imagearr[activetab])
                                );
                              }}
                            >
                              <p className="heading">{item.title}</p>
                            </Link>

                            <p
                              dangerouslySetInnerHTML={createMarkup(
                                item.summary
                              )}
                              className="desc"
                            ></p>
                          </div>
                          <Popupform title={item.title} btnstyle="button" />
                        </div>
                      );
                    })}
                  </>
                )}

                {/* {console.log(subCategory, '"sadasdsadasdsad')} */}

                {metadata.seoData && (
                  <div
                    className="seoBoxContainer"
                    dangerouslySetInnerHTML={createMarkup(metadata.seoData)}
                  ></div>
                )}
              </div>
            </div>
          ) : (
            <img src={"/assets/service/loadingicon.gif"} />
          )}
        </div>
      </div>
    </>
  );
}

export default Services;
