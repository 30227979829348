import React from "react";
import { Link } from "react-router-dom";
import "./css/Founder.scss";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
function Founder({ lid, data }) {
  function createMarkup(cont) {
    return { __html: cont };
  }

  return (
    <div
      className="foundermessage"
      id={lid ? lid : "founder"}
      style={{
        backgroundImage: `url("./assets/about/1.webp")`,
      }}
    >
      <div className="inner"></div>

      <div className="content">
        <div className="contentinner">
          <div className="manimg">
            <img src={data.img} alt="" />
          </div>

          <div className="message">
            <div className="rel">
              <div
                className="inner2"
                // dangerouslySetInnerHTML={createMarkup(data.para)}
              >
                <FaQuoteLeft className="icon1" />
                <p>
                  Embarking six decades ago, we commenced a journey to weave
                  interconnected solutions for industries globally. Beyond mere
                  supplies, we nurture businesses with essential elements that
                  foster growth and innovation.
                </p>

                <p>
                  Our legacy thrives as we orchestrate a symphony of
                  sustainability through seamless commodity pathways, enveloping
                  the world in the embrace of timely excellence.
                </p>

                <p>
                  With the best products and solutions, we strive to provide a
                  valuable experience for all. We ask for your continued
                  interest and support for Avon Commercial Corporation.
                </p>
                <FaQuoteRight className="icon2" />
              </div>
              <div className="abs">
                <p
                  className="founder"
                  // dangerouslySetInnerHTML={createMarkup(data.title)}
                >
                  Shahid Latif Awan
                </p>
                <p
                  className="founder"
                  // dangerouslySetInnerHTML={createMarkup(data.title2)}
                >
                  Founder & Chairman
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Founder;
