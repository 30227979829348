import React from "react";
import "./css/ListingLeftSec.scss";

function ListingLeftSec({ data, lid }) {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <div className="ListingLeftSec" id={lid ? lid : null}>
      <div className="leftinner">
        <div className="imgwrapper">
          <img src={data.image} />
        </div>
        <div className="textwrapper">
          <div className="inner">
            <h1>
              <span>{data.h1} </span>
              <br />
              {data.h2}
            </h1>
            <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingLeftSec;
