import axios from "axios";
import React from "react";
import { useEffect } from "react";
import "./css/Aboutnewsec.scss";
function Aboutnewsec() {
  // let data = []
  // let url = 'https://fluencer.form.artxpro.com/block_api.php'
  // // let url2 = `https://avon.admin.simboz.com/public/api/get-product/1`

  // useEffect(() => {
  //     axios.get(url)
  //         .then(function (response) {
  //             console.log(response)
  //         })
  // }, [])

  return (
    <div className="Aboutnewsec" id="about">
      <div className="inner">
        <div className="img">
          <img
            src={
              window.screen.width > 1130
                ? "assets/about.webp"
                : "assets/about5.webp"
            }
            alt="About Avon Commercial"
          />
        </div>

        <div className="rightsec">
          <h6 className="abouth1">About Us</h6>

          <div className="aboutp">
            <p>
              Avon Commercial has been providing diversified sourcing solutions
              to the manufacturing industry worldwide since past six decades.
            </p>
            <p>
              We supply commodities needed to develop, sustain and improve
              businesses.
            </p>
            <p>
              The company's sustainable growth results from maintaining a
              reliable supply chain of commodities with timely and
              quality-conscious services provided to our customers around the
              globe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutnewsec;
