import React, { useState } from "react";
import { useEffect } from "react";
import "./css/Listingbanner.scss";
import ListingPopupForm from "./ListingPopupForm";
function ListingBanner({ image, title }) {
  let [url, setUrl] = useState("");
  useEffect(() => {
    let url = window.location.href.split("/").at(-1);
    setUrl(url);
  }, []);

  let goToContact = () => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector("#footerForm").getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        100,
    });
  };

  return (
    <div
      className="bannerListing"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="bannerdata">
        <div className="contentWrapper">
          <h1>Fabrics</h1>
          {/* <p>
            The trusted fabric supplier in Pakistan. With a diverse range of
            premium fabrics, we cater to the fashion, home décor, and textile
            industries. From luxurious silks to comfortable cottons and
            everything in between, our collection showcases the finest
            craftsmanship and quality. we take pride in our commitment to
            customer satisfaction. Our team of experts is dedicated to assisting
            you in finding the perfect fabric for your unique projects. As a
            leading fabric supplier in Pakistan, we ensure timely delivery and
            competitive prices, making us the preferred choice for designers,
            artisans, and businesses alike. Explore our extensive catalog and
            witness the artistry that sets us apart. Partner with us and let
            your creations reflect the charm of Pakistani textiles.
          </p> */}
          <p>
            Pakistan's premier fabric supplier, offering diverse premium
            materials for fashion, home decor, and textiles. Luxurious silks to
            comfy cottons, reflecting finest craftsmanship. Experts help find
            perfect fabrics for unique projects. Timely delivery, competitive
            prices—designers' and artisans' preferred choice. Explore our
            catalog for Pakistani textile charm.
          </p>
          <div className="btnWrapper">
            <ListingPopupForm />
            <button onClick={goToContact}> Contact us</button>
          </div>
        </div>

        {/* {window.screen.width > 800 ? (
          <ListingPopupForm />
        ) : (
          <a href="tel:+92345 2183128"> Contact us</a>
        )} */}
      </div>
    </div>
  );
}

export default ListingBanner;
