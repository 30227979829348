import React from "react";
import "./Typage.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Typage() {
  let navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <title>Avon | Thank you</title>
        <link rel="canonical" href="https://www.avoncommercial.net/contact" />
        <meta
          name="description"
          content="Avon | Thank you"
          data-react-helmet="true"
        />
      </Helmet>
      <div className="tyPage">
        <div className="tyBox">
          <h1>Thank You!</h1>
          <p>For contacting Avoncommercial.</p>
          <button onClick={() => navigate("/")}>Go To Homepage</button>
        </div>
      </div>
    </>
  );
}

export default Typage;
