import React from "react";
import "./css/Subbanner.scss";

function Subbanner({ image, heading, title, newTitle }) {
  return (
    <div
      className="banner"
      style={{ backgroundImage: `url(${image})` }}
      aria-label={title && title}
    >
      <div className="bannerdata">
        <h6 className="h1">{heading ? heading : newTitle?.category}</h6>
      </div>
    </div>
  );
}

export default Subbanner;
