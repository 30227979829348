import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subbanner from "../components/Subbanner";
import { useContext } from "react";
import { ProductContext } from "../components/Context/ProductsContext";
import Popupform from "../components/Popupform";
import "./css/ListingMapProducts.scss";
import ListingPopupForm from "./ListingPopupForm";
function ListingMapProducts({ active }) {
  let sttop = () => {
    window.scrollTo(0, 0);
  };

  let url = "https://avon.admin.simboz.com/public/api/get-products";

  let {
    allproducts,
    setAllproducts,
    activetab,
    setActivetab,
    setSingleproduct,
    catimage,
    setCatimage,
  } = useContext(ProductContext);
  // console.log(allproducts, activetab)

  let [loading, setLoading] = useState(false);
  let [sorted, setSorted] = useState([]);
  console.log(active);
  useEffect(() => {
    sttop();

    axios
      .get(url)
      .then(function (response) {
        setAllproducts(response.data.cats);

        let scat = response.data.cats[active];

        let b = scat.products.sort((a, b) => {
          return a.sort_by - b.sort_by;
        });

        setLoading(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  let imagearr = [
    "assets/service/fabric.png",
    "assets/service/textile.png",
    "assets/service/petrochemical.png",
    "assets/service/solvents.png",
    "assets/service/basicchemical.png",
    "assets/service/filmsandsheet.png",
  ];

  localStorage.setItem("image", imagearr[activetab]);

  function createMarkup(item) {
    return { __html: item };
  }

  return (
    <div className="productwrap">
      {loading ? (
        <div className="productCont">
          <div className="productsec">
            {allproducts[active]?.products.map((item, index) => {
              return (
                <div key={index} className="productbox">
                  <div className="upperwrap">
                    <Link
                      to={`/products/${item.slug}`}
                      onClick={() => {
                        return (
                          setSingleproduct(item),
                          setCatimage(imagearr[activetab])
                        );
                      }}
                    >
                      <p className="heading">{item.title}</p>
                    </Link>

                    <p
                      dangerouslySetInnerHTML={createMarkup(item.summary)}
                      className="desc"
                    ></p>
                  </div>
                  <ListingPopupForm title={item.title} btnstyle="button" />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <img src={"/assets/service/loadingicon.gif"} />
      )}
    </div>
  );
}

export default ListingMapProducts;
