import React from "react";
import "./css/LetsWork.scss";
import ListingPopupForm from "./ListingPopupForm";

function LetsWork() {
  return (
    <div className="LetsWork">
      <div className="wrapper">
        <div className="hediWrapper">
          <p className="hedi">Why we?</p>
        </div>
        <div className="threeColSec">
          <p>1) Trusted & Reliable.</p>
          <p>2) Timely delivery.</p>
          <p>3) Competitive Pricing</p>
        </div>
      </div>
    </div>
  );
}

export default LetsWork;
