import React from 'react'
import './css/Mapbanner.scss'

function Mapbanner() {
    return (
        <div className='map'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.9806631837355!2d67.04887865029407!3d24.796115653862646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33dc2483b82a3%3A0xe135ad76ec30880a!2sAvon%20Commercial%20Corporation!5e0!3m2!1sen!2s!4v1662372750848!5m2!1sen!2s" width="600" height="450"
                style={{ border: 0, allowfullscreen: '', loading: 'lazy', referrerpolicy: "no-referrer-when-downgrade" }}
            ></iframe>
        </div>
    )
}

export default Mapbanner
