import React, { useEffect } from "react";
import "./Ceo.scss";
function Ceo({ lid }) {
  useEffect(() => {
    let sttop = () => {
      window.scrollTo(0, 0);
    };
    sttop();
  }, []);
  return (
    <div className="ceo">
      <div className="inner">
        <div className="ceoimg">
          <img src="./assets/man.png" alt="" />
          <h1>Mr. Ghazanfar</h1>
          {/* <h2> CEO</h2> */}
        </div>
        <div className="content">
          <div className="message">
            <h1>CEO's Message</h1>
            {/* <div className='lbott'></div> */}
            <div className="lines">
              <h2>Welcome to Avon Commercial Corporation!</h2>
              <p>
                It gives me great pleasure to welcome you to our website and I
                hope you learn more about the qualities that makes us a premier
                sourcing house.
              </p>
            </div>

            <div className="lines">
              <h2>We provide service with a human touch.</h2>
              <p>
                We have earned the trust and respect of our clients for one
                simple reason—we have great people. They are experts in their
                fields. They bring a strong service ethos to everything they do.
                They take pride and ownership in the job they do. We bring
                passion, pride and experience together.
              </p>
            </div>

            <div className="lines">
              <h2>We have a client first philosophy.</h2>
              <p>
                We have only one objective: to ensure our clients mission
                objectives are achieved with the highest level of capability and
                assurance, and at the lowest possible cost. Our methodology
                ensures that services are delivered on time and on schedule. Our
                clients come to us with confidence that we have the expertise to
                take service delivery to the next level of performance.
              </p>
            </div>

            <div className="lines">
              <h2>We have true international reach.</h2>
              <p>
                We have worked in many of the most demanding situations across
                the world, and our global reach means that we can support our
                clients wherever their needs require. We are able to draw upon
                the accumulated knowledge of an organization that has set new
                standards of excellence in various industries. We apply the very
                best international insights to keep our clients at the leading
                edge.
              </p>
            </div>

            <div className="lines">
              <h2>We live out our values.</h2>
              <p>
                Our corporate culture is defined by a simple set of corporate
                values that define how we operate every single day – Trust,
                Care, Passion, and Pride. They reflect how we interact with our
                clients. We hold each other accountable for creating a company
                we can all be proud to work at.
              </p>
            </div>

            <div className="lines">
              <p>
                I hope this provides you with a few reasons to get to know Avon
                Commercial Corporation better. Whether you are a potential
                customer, a small business partner, or a future employee, we
                look forward to finding out how we can work together to bring
                service to life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ceo;
